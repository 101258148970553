
<template>
  <BackofficeBase :loader_prop="loader_prop">
    <DistributionPointMenu :point_id_prod="point_id_prod" />
    <div v-if="!show_ty">
      <form @submit="save_box">
        <div class="form-group">
          <label>בחירת גודל תיבה</label>
          <br />
          <select name="" id="" class="form-control" v-model="size">
            <option value="j">ג'מבו (90x90)</option>
            <option value="l">גדול (40x40)</option>
            <option value="m">בינוני (40x20)</option>
            <option value="s">קטן (40x10)</option>
          </select>
        </div>
        <div class="form-group">
          <label>:Simpo בחירת מספר תיבת </label>
          <br />
          <input type="number" class="form-control" v-model="number" />
        </div>
        <div class="submit_cont">
          <input type="submit" value="Save" />
        </div>
      </form>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: "BackofficeAddBox",
  components: {
    BackofficeBase,
  },
  data() {
    return {
      loader_prop: true,
      view_data: [],
      id: 0,
      size: "",
      number: 0,
      show_ty: false,
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    this.loader_prop = false;
  },
  methods: {
    async save_box(e) {
      e.preventDefault();
      let params = {
        unit_id: this.id,
        size: this.size,
        number: this.number,
      };
      let backendModel = new BackendModel();
      let res = await backendModel.backendRequest(
        "/Api/service/backoffice/admin_add_box",
        params
      );
      console.log(params);
      console.log(res);
      if (res.data.res == "NOK") {
        console.log(res.data.res);
        this.loader_prop = false;
        this.usere_xist = true;
      } else {
        await this.$router.push({
          path: "/backoffice/edit_boxes",
          query: { id: this.id },
        });
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/style.css";
</style>

